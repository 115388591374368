<template>
  <CreateOrder
    @saveOrder="saveOrder"
    @cancelCreate="cancelCreate"
    :orderDetail="orderDetail"
  />
</template>

<script>
import CreateOrder from "../../../components/WebStore/orders/CreateOrder";
import { mapActions } from 'vuex';

export default {
  name: 'Add Orders',
  components:{
    CreateOrder
  },
  data: () => ({
    orderDetail: {
      orderNumber: '',
      orderType: 'Customer',
      customerId: '',
      // supplierId: '',
      // status: '',
      paymentType: '',
      isPaid: '',
      discount: '',
      creditCardTransactionFee: 0,
      gst: 0,
      subTotal: '',
      total: '',
      shippingCharge: '',
      // statusTimeLine: [],
      items: [],
      // supplierOrders: [],
      // notes: [],
      taxes: [],
      shippingDetails: {
        companyName: '',
        clientName: '',
        address: {
          displayAddress: '',
          state: '',
          suburb: '',
          postCode: '',
          geometry: {
            /*type: '',
            coordinates: {
              type: [],
              index: "2dsphere"
            }*/
          },
          country: '',
        }
      },
    }
  }),
  methods:{
    ...mapActions("storeOrder", ["createOrderByAdmin"]),
    saveOrder(data){
      let self=this;
      self.$vs.loading();
      this.createOrderByAdmin(data)
        .then(res => {
          self.$vs.notify({
            title: "Order Create",
            text: "Order created successfully",
            color: "success",
          });
          self.$vs.loading.close();
          this.$router.push({ name: 'superAdminStoreOrders'})
        })
        .catch(err => {
          self.$vs.loading.close();
          this.$vs.notify({
            title: "Order Create Failed",
            text: err.data.message,
            color: "danger"
          });
        })

    },
    cancelCreate() {
      this.$router.push({ name: "superAdminStoreOrders"});
    },


  }
};
</script>
