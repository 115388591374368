<template>
  <div>
    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="8">
        <div class="w-full m-3">
          <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
              <vs-card v-if="clinicDetails == ''">
                <div class="w-full m-5">
                  <label class="font-bold">Select Clinic</label>
                  <vs-input
                    class="w-full pr-10 search-input"
                    placeholder="Select a Clinic"
                    icon-no-border
                    icon="icon icon-search"
                    icon-pack="feather"
                    @click="selectClinicPopUp = true"
                  />
                </div>
              </vs-card>
              <vs-card v-else>
                <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
                  <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
                    <div class="w-full mt-5 ml-5">
                      <p><strong>Clinic Details</strong></p>
                    </div>
                  </vs-col>
                  <vs-col vs-type="flex" vs-justify="left" vs-align="right" vs-w="6">
                    <div class="w-full mt-5 mr-5" align="right">
                      <p class="removeClinic" @click="clearClinic()">
                        <b>x</b>
                        <!--                        <span class="hoverText">Remove Clinic</span>-->
                      </p>
                    </div>
                  </vs-col>

                  <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
                    <div class="w-full m-5">
                      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
                        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
                          <div class="w-full mb-2">
                            <p><strong>{{ clinicDetails.companyName | capitalize}} </strong></p>
                          </div>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
                          <div class="w-full mb-2">
                            <p>{{ clinicDetails.contactNumber || "NA" }}</p>
                          </div>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
                          <div class="w-full">
                            <p>{{ clinicDetails.email || "NA" }}</p>
                          </div>
                        </vs-col>
                      </vs-row>
                    </div>
                  </vs-col>
                  <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
                    <div class="w-full m-5">
                      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
                        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12" class="mt-5">
                          <div class="w-full mb-2">
                            <p>Shipping Information</p>
                          </div>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
                          <div class="w-full">
                            <p>
                              {{ clinicDetails.address.displayAddress | capitalize }}
                            </p>
                          </div>
                        </vs-col>
                      </vs-row>
                    </div>
                  </vs-col>
                </vs-row>
              </vs-card>
              <!--select clinic pop up-->
              <vs-popup fullscreen title :active.sync="selectClinicPopUp" class="p-12">
                <div class="w-full clinic-dropdown" >
                  <label class="font-bold">Select Clinic</label>
                  <v-select
                    :options="clinicOptions"
                    :clearable="false"
                    autocomplete
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="clinicFilter"
                    data-vv-as="Clinic"
                  />
                </div>
              </vs-popup>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
              <vs-card>
                <div class="w-full m-5">
                  <label class="font-bold">Select Products</label>
                  <vs-input
                    class="w-full pr-10 search-input"
                    placeholder="Search and select products"
                    icon-no-border
                    icon="icon icon-search"
                    icon-pack="feather"
                    @click="selectProduct"
                  />
                </div>
                <div v-if="selectedItems.length > 0" class="mt-5">
                  <div id="data-list-list-view" class="data-list-container">
                    <vs-table
                      ref="table"
                      :sst="true"
                      :data="selectedItems"
                    >
                      <template slot-scope="{ data }">
                        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                          <vs-td :data="data[indextr].images.image">
                            <vs-row>
                              <vs-col class="w-1/2">
                                <div style="width: 100px; height: auto;">
                                  <img
                                    class="img-fluid"
                                    :src="data[indextr].images.image"
                                    alt=""
                                  />
                                </div>
                              </vs-col>
                              <vs-col class="w-1/2 mt-10">
                                {{
                                  data[indextr].name | capitalize
                                }}
                                <br>
                                {{
                                  data[indextr].supplier || "N/A"
                                }}
                              </vs-col>
                            </vs-row>
                          </vs-td>
                          <vs-td>
                            <div v-if="data[indextr].originalPrice">
                              <s>${{ ((data[indextr].originalPrice)/100).toFixed(2) }}</s>
                            </div>
                            ${{
                              ((data[indextr].costPrice)/100).toFixed(2)
                            }}
                          </vs-td>
                          <vs-td>
                            <vs-input-number
                              v-model="data[indextr].quantity"
                              v-bind:min="data[indextr].minimumQuantity"
                              :step="1"
                              @input="updateItemQuantity($event,index, data[indextr])"
                            />
                            <span class="text-danger" style="font-size: 0.75em">{{
                                errors.firstById(data[indextr]._id)
                              }}</span>
                          </vs-td>
                          <vs-td>
                            <p class="removeClinic" @click="removeProduct(data[indextr]._id)">
                              <b>x</b>
                            </p>
                          </vs-td>
                        </vs-tr>
                      </template>
                    </vs-table>
                    <div align="right">
                      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
                        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="3">
                          <div class="w-full m-5" align="left">
                            <p>Subtotal</p> <br>
                            <p>Delivery fee</p> <br>
                            <span v-if="orderData.paymentType == 'SavedCard'"><p>2% credit card transaction fee</p> <br></span>
                            <p>GST (10%)</p> <br>
                            <p>Discounts</p> <br>
                            <p class="mt-2"><strong>Total</strong></p>
                          </div>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="left" vs-align="right" vs-w="3">
                          <div class="w-full m-5">
                            <p>${{((orderData.subTotal)/100).toFixed(2)}}</p> <br>
                            <p>${{((orderData.shippingCharge)/100).toFixed(2)}}</p> <br>
                            <span v-if="orderData.paymentType == 'SavedCard'"><p>${{((orderData.savedCardTransactionFee)/100).toFixed(2)}}</p> <br></span>
                            <p>${{((orderData.gst)/100).toFixed(2)}}</p> <br>
                            <p>-${{((orderData.discount)/100).toFixed(2)}}</p> <br>
                            <p class="mt-2"><strong>${{((orderData.total)/100).toFixed(2)}}</strong></p>
                          </div>
                        </vs-col>
                      </vs-row>
                    </div>
                  </div>
                </div>
              </vs-card>

              <!--add product pop up-->
              <vs-popup fullscreen title :active.sync="selectProductPopUp" class="p-12" vs-align="flex-start" vs-type="flex" vs-justify="left">
                <div class="w-full"   vs-type="flex" vs-justify="left" vs-align="left"
                     style="height: 340px;" >
                  <label class="font-bold">Select Product</label>
                  <vs-input
                    class="w-full search-input"
                    icon-no-border
                    icon="icon icon-search"
                    icon-pack="feather"
                    v-model="searchQuery"
                  />
                  <div v-if="productList.length >0" class="mt-5 product-table" >
                    <div id="data-list-list-view" class="data-list-container">
                      <vs-table
                        ref="table"
                        :sst="true"
                        :data="productList"
                        :max-items="dataTableParams.limit"
                      >
                        <template slot-scope="{ data }">
                          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                            <vs-td :data="data[indextr].images.image">
                              <vs-row>
                                <vs-col class="w-1/2">
                                  <div style="width: 100px; height: auto;">
                                    <img
                                      class="img-fluid"
                                      :src="data[indextr].images.image"
                                      alt=""
                                    />
                                  </div>
                                </vs-col>
                                <vs-col class="w-1/2 mt-10">
                                  {{
                                    data[indextr].name | capitalize
                                  }}
                                  <br>
                                  {{
                                    data[indextr].supplier || "N/A"
                                  }}
                                </vs-col>
                              </vs-row>
                            </vs-td>
                            <vs-td>
                              <div v-if="data[indextr].originalPrice">
                                <s>${{ ((data[indextr].originalPrice)/100).toFixed(2) }}</s>
                              </div>
                              ${{
                                ((data[indextr].price)/100).toFixed(2)
                              }}
                            </vs-td>
                            <vs-td>
                              <label class="form-checkbox">
                                <input type="checkbox" :value="data[indextr]" :disabled="data[indextr].canBuy == false" v-model="selectedProducts" />
                                <i class="form-icon"></i>
                              </label>
                              <!--                              <div v-if="data[indextr].canBuy == false" style="font-size: 8px;color:red">Contact Fresh</div>-->
                            </vs-td>
                          </vs-tr>
                        </template>
                      </vs-table>
                    </div>
                  </div>
                </div>
                <div class="mt-12 footer" align="right">
                  <vs-button @click="addProductToOrder" :disabled="selectedProducts.length == 0">Add to order</vs-button>
                </div>
              </vs-popup>
            </vs-col>
          </vs-row>
        </div>
      </vs-col>
      <!--      payment details-->
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="4">
        <div class="w-full m-3">
          <vs-card v-if="clinicDetails == ''">
            <div class="w-full m-5">
              <label class="font-bold">Payment Type</label>
              <p>Select  clinic to choose the payment type</p>
            </div>
          </vs-card>
          <vs-card v-else>
            <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
              <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
                <div class="w-full m-5">
                  <div v-if="clinicDetails.hasOwnProperty('financialDetails')">
                    <vs-radio v-model="orderData.paymentType" vs-value="SavedClinicBankAccount">Clinic Bank Account</vs-radio>
                    <br>
                    <div class="ml-5">
                      <p>Account Holder Name <br>
                        <strong>{{clinicDetails.financialDetails.accountHolderName}}</strong>
                      </p>
                      <p>BSB <br>
                        <strong>{{clinicDetails.financialDetails.bsb}}</strong>
                      </p>
                      <p>Account Number <br>
                        <strong>{{clinicDetails.financialDetails.accountNumber}}</strong>
                      </p>
                    </div>
                  </div>
                  <div v-else>
                    <vs-radio disabled="true">Clinic Bank Account</vs-radio>
                    <p>Bank details unavailable</p>
                  </div>
                </div>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
                <div class="w-full m-5">
                  <vs-radio v-model="orderData.paymentType" vs-value="SavedCard">Pay By Card</vs-radio>
                </div>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
                <div class="w-full m-5">
                  <vs-radio v-model="orderData.paymentType" vs-value="PaymentTermsInvoice">Payment Terms - Invoice</vs-radio>
                </div>
              </vs-col>
            </vs-row>
          </vs-card>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
        <div class="w-full mt-10" align="right">
          <vs-button type="border" @click="cancelCreate" class="mr-5">Cancel</vs-button>
          <vs-button :disabled="!validateForm" @click="saveData">Create Order</vs-button>
        </div>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import { mapActions } from 'vuex';
import StripeElements from '../../order-center/StripeElements';

export default {
  name: 'AddOrder',
  components: {
    vSelect
  },
  props:{
    orderDetail: {
      type: Object,
      default: () => {
      },
    }
  },
  data() {
    return {
      orderData: this.orderDetail,
      clinicOptions: [],
      clinicFilter: '',
      clinicQuery: '',
      clinicDetails: '',
      productQuery: '',
      selectClinicPopUp: false,
      selectProductPopUp: false,
      paymentOption: '',
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 5,
        clinicId: ""
      },
      searchQuery:'',
      awaitingSearch: false,
      productList:[],
      selectedProducts:[],
      selectedItems: [],
      cardDetails: null,
      stripePublishableKey:process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      token:'',
      charge:{}
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any() &&
        this.orderData.customerId !== '' &&
        this.orderData.orderType !== '' &&
        this.orderData.paymentType !== '' &&
        this.orderData.discount !== ''  &&
        this.orderData.shippingDetails.companyName !== '' &&
        this.orderData.shippingDetails.clientName !== '' &&
        this.orderData.subTotal !== '' &&
        this.orderData.total !== '' &&
        this.selectedItems.length !== 0
    },
  },
  mounted() {},
  methods: {
    ...mapActions("clinic", [
      "fetchAllClinic",
      "fetchClinicsDetail"
    ]),
    ...mapActions("storeOrder", [
      "fetchProductsForOrder"
    ]),
    ...mapActions("general", [
      "fetchClinicCardDetailsForAdmin"
    ]),
    async saveData() {
      let isValid = await this.$validator.validateAll('basic');
      if (!isValid) {
        this.$vs.notify({
          title: 'Error',
          text: 'Validation Failed',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        });
        return false;
      }
      this.orderData.items = await this.selectedItems.map((item) => {
        return{
          productStoreId: item._id,
          supplierId: item.supplierId,
          costPrice: item.costPrice,
          customAdded: true,
          quantity: item.quantity,
          discountPerItem: item.discount
        }
      });
      this.charge = {
        amount: this.orderData.total
      };
      const order = {
        data: {
          charge:this.charge,
          shippingDetails:this.orderData.shippingDetails,
          paymentType:this.orderData.paymentType
        },
        clinicDetails: this.clinicDetails,
        orderData: this.orderData
      }
      this.$emit('saveOrder', order);
    },
    cancelCreate() {
      this.$emit('cancelCreate');
    },
    clearClinic() {
      this.clinicDetails = '';
      this.clinicFilter = '';
      this.selectedItems = [];
      this.selectedProducts = [];
      this.searchQuery = '';
      this.initializeValue();
    },
    selectProduct() {
      if(this.clinicDetails == '') {
        this.$vs.notify({
          title: "Clinic not selected",
          text: "Please select clinic to select product",
          color: "danger"
        })
      } else if(this.orderData.paymentType == ''){
        this.$vs.notify({
          title: "Payment not selected",
          text: "Please select payment method first",
          color: "danger"
        })
      } else {
        this.selectProductPopUp = true;
      }
    },
    getProductList(){
      this.dataTableParams.search = this.searchQuery;
      this.dataTableParams.clinicId  = this.clinicDetails._id;
      this.fetchProductsForOrder(this.dataTableParams).then(res => {
        this.productList = res.data.data.docs;
        this.totalDocs = res.data.data.pagination.total;
        this.page = res.data.data.pagination.page;
      });
    },
    async addProductToOrder(){
      this.searchQuery = '';
      this.selectProductPopUp = false;
      this.$vs.loading();
      this.selectedItems = await this.selectedProducts.map((item) => {
        return{
          _id: item._id,
          images: item.images,
          name: item.name,
          supplier: item.supplier,
          productStoreId: item._id,
          supplierId: item.supplierId,
          costPrice: item.price,
          originalPrice : item.originalPrice ? item.originalPrice : null,
          customAdded: true,
          discount: item.originalPrice ? (item.originalPrice - item.price ) : 0,
          quantity: item.requiredQuantity ? item.requiredQuantity : 1,
          deliveryFee: item.deliveryFee,
          inventory: item.inventory,
          minimumQuantity: item.requiredQuantity ? item.requiredQuantity : 1
        }
      })
      await this.calculatePricing();
      this.$vs.loading.close();
    },
    async updateItemQuantity(event, index, data) {
      if(data.quantity < data.minimumQuantity) {
        this.errors.add({
          field: "MinimumQuantityLimit",
          msg: "Minimum order quantity of " + data.name + " is "  + data.minimumQuantity,
          id: data._id
        });
      } else {
        this.errors.removeById(data._id);
        await this.initializeValue();
        await this.shippingChargeHandler(this.selectedItems);
        this.selectedItems.forEach((item) => {
          if (item._id == data._id) item.quantity = data.quantity;
          if (item.originalPrice) {
            this.orderData.subTotal = this.orderData.subTotal + (item.originalPrice * item.quantity);
          } else {
            this.orderData.subTotal = this.orderData.subTotal + (item.costPrice * item.quantity);
          }
          this.orderData.discount = this.orderData.discount + (item.discount * item.quantity);
        })
        this.orderData.total = this.orderData.subTotal + this.orderData.shippingCharge - this.orderData.discount;
        await this.gstHandler(this.orderData);
        await this.savedCardTransactionFeeHandler(this.orderData);
      }
    },
    async removeProduct(id) {
      this.selectedProducts = this.selectedProducts.filter( obj => obj._id !== id);
      this.selectedItems = await this.selectedItems.filter( obj => obj._id !== id);
      await this.calculatePricing();
    },
    async shippingChargeHandler(data) {
      let charge = 0;
      // group item by supplier Id
      function groupBy(objectArray, property) {
        return objectArray.reduce(function (arr, obj) {
          var key = obj[property];
          if (!arr[key]) {
            arr[key] = [];
          }
          arr[key].push(obj);
          return arr;
        }, {});
      }
      const supplierItem = await groupBy(data, 'supplierId');
      const productArr = Object.values(supplierItem);
      await Promise.all(productArr.map(async (item) => {
        charge = charge + item[0].deliveryFee;
      }));
      this.orderData.shippingCharge = charge;
    },
    async gstHandler(data) {
      const subTotal = data.subTotal;
      this.orderData.gst = await subTotal * 0.1;
      this.orderData.total = this.orderData.total + this.orderData.gst;
    },
    async savedCardTransactionFeeHandler(data) {
      if(data.paymentType == 'SavedCard'){
        this.orderData.savedCardTransactionFee = this.orderData.subTotal * 0.02;
      }
      this.orderData.total = this.orderData.total + this.orderData.savedCardTransactionFee;
    },
    initializeValue(){
      this.orderData.subTotal = 0;
      this.orderData.total = 0;
      this.orderData.discount = 0;
      this.orderData.gst = 0;
      this.orderData.savedCardTransactionFee = 0;
    },
    async calculatePricing(){
      await this.initializeValue();
      await this.shippingChargeHandler(this.selectedItems);
      this.selectedItems.forEach((item) => {
        if(item.originalPrice) {
          this.orderData.subTotal = this.orderData.subTotal + (item.originalPrice * item.quantity);
        } else {
          this.orderData.subTotal = this.orderData.subTotal + (item.costPrice * item.quantity);
        }
        this.orderData.discount = this.orderData.discount + ( item.discount * item.quantity);
      })
      this.orderData.total = this.orderData.subTotal + this.orderData.shippingCharge - this.orderData.discount;
      await this.gstHandler(this.orderData);
      await this.savedCardTransactionFeeHandler(this.orderData);
    },
    tokenCreated (token) {
      this.token = token;
      this.charge = {
        source: token.id,
        amount: this.orderData.total,
      }
    },
  },
  watch: {
    async clinicFilter(val) {
      if(this.clinicFilter != '') {
        this.$vs.loading();
        await this.fetchClinicsDetail(val.value)
          .then((res) => {
            this.clinicDetails = res.data.data;
          })
        if (this.clinicDetails.hasOwnProperty('financialDetails')) {
          if (this.clinicDetails.paymentDetails && this.clinicDetails.paymentDetails.cardId) {
            const info = {
              paymentDetails: this.clinicDetails.paymentDetails
            }
            await this.fetchClinicCardDetailsForAdmin(info)
              .then((res) => {
                this.cardDetails = res.data.data;
              })
          }
          this.orderData.customerId = this.clinicDetails._id;
          // console.log("customerId", this.orderData.customerId);
          this.orderData.shippingDetails = {
            companyName: this.clinicDetails.clinicName,
            clientName: this.clinicDetails.firstName + ' ' + this.clinicDetails.lastName,
            address: {
              displayAddress: this.clinicDetails.address.displayAddress,
              state: this.clinicDetails.address.state,
              suburb: this.clinicDetails.address.suburb,
              postCode: this.clinicDetails.address.postCode,
              geometry: this.clinicDetails.address.geometry
            }
          };
          this.orderData.paymentType = '';
          this.selectClinicPopUp = false;
          this.$vs.loading.close();
        } else {
          this.orderData.paymentType = '';
          this.selectClinicPopUp = false;
          this.$vs.loading.close();
          /*this.$vs.notify({
            title: "Cannot place order",
            text: "Payment details unavailable for clinic",
            color: "danger"
          });*/
        }
      }
    },
    searchQuery: function (val) {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          if(this.searchQuery != '') {
            this.getProductList();
          } else {
            this.productList = []
          }
          this.awaitingSearch = false;
        }, 1000);
      }
      this.awaitingSearch = true;
    },
    'orderData.paymentType': function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.calculatePricing();
      }
    },
  },
  async created() {
    this.$vs.loading()
    this.fetchAllClinic()
      .then(async res => {
        this.$vs.loading.close();
        let data = res.data.data;
        this.clinicOptions = await data.map((item) => {
          return {
            label: item.clinicName,
            value: item._id
          }
        });
        this.orderData.customerId = this.clinicFilter.value;
      })
  }
};
</script>

<style scoped>
.removeClinic {
  cursor: pointer;
  color: black;
  font-size: 15px;
}
.product-table {
  height: 80%;
  overflow: auto;
}

.clinic-dropdown {
  /*height: 100%;*/
  /*position: absolute;*/
  min-height: 450px!important;

}


</style>
